export const environment = {
    production: false,
    HOST: 'https://sit-app.scenepro.ca',
    BACKEND: 'https://sit-api.scenepro.ca/api/',
    DASHBOARD: '/dashboard',
    IS_PRODUCTION: true,
    B2C_CLIENT_ID: 'undefined',
    B2C_SCOPE: 'undefined',
    B2C_AUTH: 'undefined',
    B2C_LOGIN: 'undefined',
    B2C_WSHOST: 'undefined',
    APPINSIGHTS_INSTRUMENTATION_KEY: 'dccc08b1-b65f-4f44-93a2-3bfd0c799325',
    APPINSIGHTS_SAMPLING_PERCENTAGE: 100,
    AZ_FORGET: 'undefined',
    AZ_EDIT_REDIR: 'undefined',
    NODE_ENV: 'development-sit',
    GTM_ID: 'GTM-NCJ7N4M',
    CALENDAR_FREE_UNTIL: 'undefined',
    CAN_DIRECTLY_LOGIN: false,
    VITRINE_URL: 'https://sit.scenepro.ca/',
    TIMEOUT_INDEXED_DB: '10000'
};